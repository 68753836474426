<template>
  <v-row>
    <v-col cols="12">
      <v-card class="card_">
        <div class="avatar">
          <v-avatar class="avatar-image" color="info" size="110" contain tile>
            <v-img
              :src="`${bucketUrl}${this.picture_url}`"
              max-width="110"
              max-height="110"
              :alt="this.name"
              v-if="this.picture_url"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span v-else class="white--text text-h4">{{ initials }}</span>
          </v-avatar>
        </div>
        <v-card-title class="justify-center flex-column mt-14">
          <span class="mb-1">{{ this.name }}</span>
          <span class="mt-1">ID: {{ this.unique_id }}</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <h2 class="text-xl font-weight-semibold mb-2">{{ $t("Details") }}</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
              style="font-size: 0.8rem"
            >
              <span class="font-weight-medium me-2">{{ $t("Email") }}:</span>
              <span class="text--secondary"
                ><a :href="`mailto:${email}`">{{ email }}</a></span
              >
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2"
                >{{ $t("Telephone") }}:</span
              >
              <span class="text--secondary"
                ><a :href="`tel:${countryPhoneCode}${phone}`">{{
                  countryPhoneCode + phone
                }}</a></span
              >
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mdiCheck, mdiClose } from "@mdi/js";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  computed: {
    ...mapState("auth", [
      "name",
      "email",
      "countryPhoneCode",
      "phone",
      "picture_url",
      "unique_id",
    ]),
    initials() {
      if (this.name) {
        const fullName = this.name.split(" ");
        if (fullName.length === 2) {
          const initials =
            fullName.shift().charAt(0) + fullName.pop().charAt(0);
          return initials.toUpperCase();
        } else {
          const initials = this.name[0];
          return initials.toUpperCase();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  background-color: #000522;
  min-height: 100px;
  position: relative;
  &-image {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
  }
}
.card_ {
  min-height: 300px;
}
</style>
